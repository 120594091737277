<template>
	<div class="category-container">
		<div class="title-container"><div>类目设置</div></div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="平台" name="platform">
				<breadcrumb :BreadcrumbData="BreadcrumbData1"></breadcrumb>
				<el-button class="common-screen-btn" type="primary" @click="addDrawer('新增平台', '', 0, 0)">新增平台</el-button>
				<el-table v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="common-table" :data="tableData">
					<el-table-column prop="platformName" label="平台名称"></el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button type="text" size="small" @click="() => addDrawer('编辑平台', scope.row.platformName, 0, scope.row.id, scope.row.elementUuid)">编辑</el-button>
							&nbsp;&nbsp;&nbsp;
							<el-popconfirm placement="top" title="确定删除吗？" @confirm="() => deleteItem(scope.row.id)">
								<el-button type="text" size="small" slot="reference">删除</el-button>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
			<!-- 我的报表 -->
			<el-tab-pane label="类目" name="category">
				<breadcrumb :BreadcrumbData="BreadcrumbData2"></breadcrumb>
				<el-button class="common-screen-btn" type="primary" @click="addDrawer('新增类目', '', 0, 0)">新增类目</el-button>
				<el-table v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="common-table" :data="tableData">
					<el-table-column prop="platformName" label="类目名称"></el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<div v-if="scope.row.canEdit == 1">
								<el-button type="text" size="small" @click="() => addDrawer('编辑类目', scope.row.platformName, 0, scope.row.id)">编辑</el-button>
								&nbsp;&nbsp;&nbsp;
								<el-popconfirm placement="top" title="确定删除吗？" @confirm="() => deleteCategory(scope.row.id)">
									<el-button type="text" size="small" slot="reference">删除</el-button>
								</el-popconfirm>
							</div>
							<div v-if="scope.row.canEdit != 1">
								<el-button type="text" size="small" style="color: #ccc">编辑</el-button>
								<el-button type="text" size="small" style="color: #ccc">删除</el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
		</el-tabs>
		<el-pagination
			@size-change="(e) => pageFun(e, 'pageSize')"
			@current-change="(e) => pageFun(e)"
			style="text-align: center"
			:page-sizes="pagination.pageSizes"
			:page-size="params.pageSize"
			:current-page="params.pageNum"
			layout="total, prev, pager, next,sizes, jumper"
			:total="pagination.total"
			>
		</el-pagination>
		<!-- <el-button class="common-screen-btn" type="primary" @click="addDrawer('新增平台', '', '',0)">新增商家平台</el-button> -->
		<!-- <el-table :row-class-name="getRowClass" class="common-table" :data="tableData">
			<el-table-column type="expand">
				<template slot-scope="scope">
					<el-table class="common-table twoTable" :show-header="false" :data="scope.row.children">
						<el-table-column prop="platformName" label="分类名称"></el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scopeTwo">
								<el-button type="text" size="small" @click=" () => addDrawer('编辑类目', scopeTwo.row.id, scopeTwo.row.platformName,scopeTwo.row.parentId)">编辑</el-button>
								&nbsp;&nbsp;&nbsp;
								<el-popconfirm placement="top" title="确定删除吗？" @confirm="deleteItem(scopeTwo.row.id)">
									<el-button type="text" size="small" slot="reference">删除</el-button>
								</el-popconfirm>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</el-table-column>
			<el-table-column prop="platformName" label="分类名称"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="() => addDrawer('新建类目', 0, '',scope.row.id)">新建类目</el-button>
					<el-button type="text" size="small" @click="() => addDrawer('编辑平台', scope.row.id, scope.row.platformName,scope.row.parentId)">编辑</el-button>
					&nbsp;&nbsp;&nbsp;
					<el-popconfirm placement="top" title="确定删除吗？" @confirm="() => deleteItem(scope.row.id)">
						<el-button type="text" size="small" slot="reference">删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table> -->
		<category-add ref="categoryAdd" :drawer="drawer"  :addType="addType" @handleClose="handleClose" @platformList="platformList"></category-add>
	</div>
</template>
<script>
	import Breadcrumb from "../../components/common/breadcrumb.vue";
	import CategoryAdd from "./children/CategoryAdd.vue";
	import { platformList, categoryList, delPlat, deleteCategory } from "../../service/customer.js";
	export default {
		components: {
			Breadcrumb,
			CategoryAdd,
		},
		data() {
			return {
				BreadcrumbData1: [
					{ title: "首页", isLink: true, url: "/index" },
					{ title: "营销", isLink: false },
					{ title: "设置", isLink: false },
					{ title: "类目设置", isLink: false },
					{ title: "平台", isLink: false },
				],
				BreadcrumbData2: [
					{ title: "首页", isLink: true, url: "/index" },
					{ title: "营销", isLink: false },
					{ title: "设置", isLink: false },
					{ title: "类目设置", isLink: false },
					{ title: "类目", isLink: false },
				],
				loading: false,
				activeName: 'platform',
				addType: "", // 新建类目 / 新建平台 / 编辑类目 / 编辑平台
				params: {
					pageNum: 1,
					pageSize: 10
				},
				pagination: {
					total: 0,
					pageSizes: [5, 10, 20, 50, 100],
				},
				ruleForm: { // 修改密码
					name: "",
				},
				rules: {
					name: [{
						required: true,
						message: "请填写密码",
						trigger: "change"
					}],
				},
				drawer: false, // 新增员工弹窗
				tableData: [],
			};
		},
		created() { 
			this.platformList()
		},
		methods: {
			async deleteCategory (id) { // 删除类目
				let { code } = await deleteCategory({ id })
				if ( code == 200 ) {
					this.$message.success("删除成功")
					this.platformList()
				}
			},
			pageFun(e, type) { // 分页
				if (type == "pageSize") {
					this.params.pageSize = e;
				} else {
					this.params.pageNum = e;
				}
				this.platformList();
			},
			handleClick(tab) { // 切换tab
				console.log(tab)
				this.params.pageNum = 1;
				this.params.pageSize = 10
				this.platformList();
				// this.$refs[tab.name].reportList()
			},
			async platformList() {
				let params = {...this.params}
				let data;
				this.loading = true
				if (this.activeName == 'platform') {
					data = (await platformList(params)).data
				} else {
					data = (await categoryList(params)).data
				}
				this.loading = false
				this.tableData = data.list;
				this.pagination.total = data.total
			},
			getRowClass(row) {
				let res = [];
				if (!row.row.children) {
					res.push("row-expand-cover");
				}
				return res.join(" ");
			},
			handleClose() {
				this.drawer = false;
			},
			addDrawer(type, name, pId, id, elementUuid) {
				// type : '新建类目 id=0 parentId  / 新建平台 id=0 paId=0/ 编辑类目id,paId / 编辑平台 id
				this.$refs["categoryAdd"].setPlatName(name, pId, id, elementUuid);
				this.addType = type;
				this.drawer = true;
			},
			async deleteItem(id) {
				let { code } = await delPlat({}, id);
				if ( code == 200 ) {
					this.$message.success("删除成功");
					this.platformList();
				}
				
			},
		},
	};
</script>
<style lang="less" scoped>
	.category-container {
		text-align: left;
		.twoTable {
			margin: 0;
			border: 0;

			&::before {
				display: none;
			}
		}

		.nameIcon {
			width: 32px;
			height: 32px;
			color: #f56a00;
			background-color: #fde3cf;
			border-radius: 50%;
			text-align: center;
			line-height: 32px;
		}
	}

	.dialog-input {
		width: 320px !important;
	}

	/deep/ .el-table__expanded-cell {
		padding: 0 0 0 78px !important;
	}

	/deep/ .row-expand-cover .el-table__expand-column .el-icon {
		visibility: hidden;
	}
	/deep/ .el-tabs__content{
    padding: 0 32px;
  }
	.title-container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 20px 32px;
  align-items: center;
  position: relative;
  &::after {
    content: " ";
    background: #e6e6e6;
    width: 120%;
    height: 1px;
    position: absolute;
    left: -10%;
    bottom: -1px;
    z-index: 999999;
  }
  div {
    font-size: 24px;
    color: #101010;
  }
}
.el-tabs {
	margin-top: 24px;
}
</style>
